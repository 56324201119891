<template>
    <b-card-code no-body title="All banned users">
        <div class="m-2">
            <!-- Table Top -->
            <b-row>
                <!-- Per Page -->
                <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                <!-- page length -->
                <b-form-group
                    label="Show"
                    label-cols="5"
                    label-align="left"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="text-nowrap mb-md-0 mr-1"
                >
                    <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                    />
                </b-form-group>
                </b-col>
            </b-row>
        </div>
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="data"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: Name -->
            <template #cell(name)="data">
                <router-link :to="{name: 'user-questionnaires', params: {id: data.item.id} }">
                    <span class="font-weight-bold d-block text-nowrap">
                        {{ data.item.name }}
                    </span>
                </router-link>
            </template>

            <!-- Column: Username -->
            <template #cell(username)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.username }}
                </span>
            </template>
            
            <!-- Column: Email -->
            <template #cell(email)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.email }}
                </span>
            </template>

            <template #cell(ban)="data">
                <b-badge variant="light-success" @click="unban(data.item.id, data.item.username, data.item.name)">
                    <feather-icon icon="RefreshCwIcon" />
                </b-badge>
            </template>

            <template #cell(delete)="data">
                <b-badge variant="light-danger" @click="removeUser(data.item.id)">
                    <feather-icon icon="Trash2Icon" />
                </b-badge>
            </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode
    },
    data() {
        return {
        data: [],
        perPage: 10,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        fields: [
            { key: "name", label: "Full Name" },
            { key: "username", label: "Username" },
            { key: "email", label: "Email" },
            { key: "ban", label: "Ban" },
            { key: "delete", label: "Delete", class: "text-center" },
        ],
        status: [{
            0: 'No', 1: 'Yes'
        },
        {
            0: 'light-danger', 1: 'light-success',
        }],
        };
    },
    methods: {
        getAllUsers() {
            //Return questionnaires list
            this.$http.get("/api/auth/admin/banned-users")
                .then((res) => {
                if(res != undefined) {
                    this.data = res.data
                    // Set the initial number of items
                    this.totalRows = this.data.length;
                }
                })
                .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },
        removeUser(user_id) {
            this.$bvModal
                .msgBoxConfirm('All data for the selected user will be deleted!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/users/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllUsers();                 
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        unban(user_id, username, fullName) {        
            this.$bvModal
                .msgBoxConfirm('User ' + fullName + ' (' + username + ') will be unbanned from the app!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.get("/api/auth/admin/users/unban/" + user_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The user was successfully unbanned from the app!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });     
                        this.getAllUsers();
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error unbanning the user!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
    },
    mounted() {
        this.getAllUsers();
    }
}
</script>

<style scoped>

</style>